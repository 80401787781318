import React from "react"
import BasicText from "../../components/BasicText"
import LayoutBasic from "../../components/LayoutBasic"
import AnswerBox from "../../components/AnswerBox"
import Seo from "../../components/Seo"
import QuestionAndAnswerToggle from "../../components/QuestionAndAnswerToggle"
import CenterContentLayout from "../../components/CenterContentLayout"
import css from "./stylesheets/faq.module.scss"

export default props => (
  <div>
    <Seo title={`FAQ`} lang={`en`} />
    <LayoutBasic narrow={true} lang={`en`}>
      <BasicText>
        <CenterContentLayout narrow={true}>
          <h1>FAQ</h1>
          <p>
            These are the frequently asked questions. This section introduces
            some useful organizations and services.
          </p>

          <div>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`Who should I ask for help when I am in trouble?`}
            >
              <AnswerBox>
                <p>
                  At first, please ask your coworkers, supervising organizations
                  or registered support organizations. When you are still unable
                  to solve the problem, please contact other free advisory
                  services.
                  <br />
                  You can talk to a translator and a lawyer at the ‘Japan Legal
                  Support Center.’ Depending on your problems such as, work,
                  accidents, owning debts and natural disasters, they will
                  introduce you to legal experts in Japan, for free. They also
                  provide a service loan to pay for the legal fee in advance.
                  <br />
                  You can not only ask for help when you are in trouble but also
                  ask for clues for your daily life at the ‘Asian People’s
                  Friendship Society.’
                  <br />
                  Anyone can make a phone call for advice related to work, to
                  the ‘Tokyo Labor Bureau.’
                  <br />
                  The local ‘Ministry of Justice’ will give you advice on human
                  rights through phone calls and emails.
                  <br />
                  International care workers can ask for any advice at the
                  ‘International Welfare Agency (JICWELS)’by phone calls,
                  emails, texting through Facebook and LINE.
                  <br />
                  Technical intern trainees can call or email to the
                  ‘Organization for Technical Intern Training.’
                  <br />
                  ‘Municipal offices’ and ‘international exchange associations’
                  of some prefectures, cities and districts provide advisory
                  services.
                </p>
                <div class={css.links}>
                  <p>
                    Nationally Established Consultation Services by Lawyers｜
                    <a
                      href={`https://www.houterasu.or.jp/multilingual/english/index.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      Japan Legal Support Center
                    </a>
                    <br />
                    <small>
                      （Easy Japanese / Japanese / English / Chinese / Korean /
                      Pilipino / Vietnamese / Thai / Nepali / Spanish /
                      Portuguese）
                    </small>
                  </p>
                  <p>
                    Consultation by Nonprofit Organization｜
                    <a
                      href={`http://apfs.jp/eng/talkwithapfs`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      Asian People’s Friendship Society
                    </a>
                    <br />
                    <small>
                      （English / Chinese / Korean / Pilipino / Burmese /
                      Bengali/ Urdu / Persian / French）
                    </small>
                  </p>

                  <p>
                    Advisory Services on Working Conditions for International
                    Workers｜
                    <a
                      href={`https://jsite.mhlw.go.jp/tokyo-roudoukyoku/news_topics/kyoku_oshirase/roudou_kijun/20050415-foreigner.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      Tokyo Labor Bureau
                    </a>
                    <br />
                    <small>
                      （Japanese / English / Chinese / Pilipino / Vietnamese /
                      Burmese/ Bengali/ Nepali / Spanish / Portuguese）
                    </small>
                  </p>
                  <p>
                    Advisory Services on Human Rights for Foreign Nationals｜
                    <a
                      href={`http://www.moj.go.jp/JINKEN/jinken21.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      The Japan Ministry of Justice
                    </a>
                    <br />
                    <small>
                      （Japanese / English / Chinese / Korean / Pilipino /
                      Vietnamese / Portuguese）
                    </small>
                  </p>
                  <p>
                    National Certified Consultation Support Desk for
                    International Care Workers｜
                    <a
                      href={`https://jicwels.or.jp/?page_id=9447`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      International Welfare Agency (JICWELS)
                    </a>
                    <br />
                    <small>
                      （Japanese / English / Chinese / Pilipino / Vietnamese /
                      Indonesian / Burmese）
                    </small>
                  </p>
                  <p>
                    National Certified Consultation Services for Technical
                    Intern Trainee｜
                    <a
                      href={`https://www.otit.go.jp`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      Organization for Technical Intern Training
                    </a>
                    <br />
                    <small>
                      （English / Chinese / Pilipino / Vietnamese / Khmer / Thai
                      / Indonesian / Burmese）
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`What should I do when I get sick?`}
            >
              <AnswerBox>
                <p>
                  There are not many hospitals where you can ask for help in
                  your language, so please go to see doctors with someone who
                  can speak Japanese as much as possible. If you cannot find
                  anyone to accompany you, please call the ‘AMDA International
                  Medical Information Center’ to assist you with finding
                  hospitals where you can speak your own language or translate
                  over the phone.
                  <br />
                  Please bring your ‘health insurance card’ to a hospital. You
                  also need your certification such as ‘residence card’ and
                  ‘passport.’ In case you have allergies or any medication
                  prescribed, or any limitations for lifestyle and treatment for
                  religious reasons, please let nurses know in advance.
                </p>
                <div class={css.links}>
                  <p>
                    Medical Consultation by Nonprofit Organization｜
                    <a
                      href={`https://www.amdamedicalcenter.com/activities`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      AMDA International Medical Information Center
                    </a>
                    <br />
                    <small>
                      （Easy Japanese / English / Chinese / Korean / Pilipino /
                      Vietnamese / Thai / Spanish / Portuguese）
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`How can I improve my Japanese?`}
            >
              <AnswerBox>
                <p>
                  To improve your Japanese, it is important to repeatedly write,
                  read, listen and speak in your daily life. Please try writing
                  and having conversations as often as you can.
                  <br />
                  Other than learning the language, you can also study about
                  nursing care and Japanese culture at the same time through
                  these websites: ‘Nihongo de Care Navi,’ ‘Nihongo wo Manabou,’
                  and ‘MARUGOTO.’
                </p>
                <div class={css.links}>
                  <p>
                    Learning Japanese Used in Nursing Care,{" "}
                    <a
                      href={`https://eng.nihongodecarenavi.jp`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      ‘Nihongo de Care Navi’
                    </a>{" "}
                    by a Nationally Certified Organization | The Japan
                    Foundation
                    <br />
                    <small>（Japanese / English / Indonesian）</small>
                  </p>
                  <p>
                    Learning Japanese Used in Nursing Care,{" "}
                    <a
                      href={`https://aft.kaigo-nihongo.jp/rpv/default.aspx?k=zdqskJ%2b11FdmiQMOKzZj3g%3d%3d`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      ‘Nihongo wo Manabou’
                    </a>{" "}
                    by a Nationally Certified Organization | The Japan
                    Association of Certified Care Workers
                    <br />
                    <small>（Japanese / English）</small>
                  </p>
                  <p>
                    Learning Japanese Used in Nursing Care,{" "}
                    <a
                      href={`https://marugotoweb.jp/en/index.php`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      ‘MARUGOTO’
                    </a>{" "}
                    by a Nationally Certified Organization | The Japan
                    Foundation
                    <br />
                    <small>（Japanese / English / Spanish）</small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`Is there anything that we should be aware of living in Japan?`}
            >
              <AnswerBox>
                <p>
                  There are some rules which may differ from your culture. For
                  example, you need to sort trash according to its materials,
                  and flush toilet papers down the toilet after use.
                  <br />
                  As for the living information about Japan, please look at
                  these websites: ‘Multilingual Living Information,’ ‘A Daily
                  Life Support Portal for Foreign Nationals’ and ‘Handbook for
                  Learning Japanese and Life in Japan.’
                </p>
                <div class={css.links}>
                  <p>
                    <a
                      href={`http://www.clair.or.jp/tagengorev/en/index.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      ‘Multilingual Living Information’
                    </a>{" "}
                    for Foreign Nationals by a Foundation | Council of Local
                    Authorities for International Relations
                    <br />
                    <small>
                      （Easy Japanese / Japanese / English / Chinese / Korean /
                      Pilipino / Vietnamese / Thai / Nepali / Indonesian /
                      Burmese / Russian / German / French / Spanish /
                      Portuguese）
                    </small>
                  </p>
                  <p>
                    <a
                      href={`http://www.moj.go.jp/EN/nyuukokukanri/kouhou/m_nyuukokukanri10_00001.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      ‘A Daily Life Support Portal for Foreign Nationals’
                    </a>{" "}
                    Provided by Japan | The Japan Ministry of Justice
                    <br />
                    <small>
                      （Easy Japanese / Japanese / English / Vietnamese）
                    </small>
                  </p>
                  <p>
                    <a
                      href={`https://www.bunka.go.jp/english/policy/japanese_language/education/handbook`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      ‘Handbook for Learning Japanese and Life in Japan’
                    </a>{" "}
                    Provided by Japan | Agency for Cultural Affairs
                    <br />
                    <small>
                      （Japanese / English / Chinese / Korean / Spanish /
                      Portuguese）
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`Is it possible to pray everyday?`}
            >
              <AnswerBox>
                <p>
                  Praying or wearing hijabs at work depends on your workplace.
                  However, every nursing care facility is expected to respect
                  different religions and cultures.
                  <br />
                  There are more and more nursing care facilities that have
                  understandings towards the religious and cultural differences
                  these days, so please confirm the conditions and working
                  environment before applying to nursing care facilities.
                </p>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`What if I cannot eat Japanese food?`}
            >
              <AnswerBox>
                <p>
                  In Japan, you have access to different types of cuisine from
                  all over the world. There are many grocery shops where you can
                  buy ingredients from your country. Online shopping is also
                  useful. Many of the international workers cook their own
                  meals.
                  <br />
                  The other options are buying readymade meals at supermarkets
                  or convenience stores and eating at restaurants. We hope that
                  you find and enjoy your own style.
                </p>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`How much can we transfer to our families back home?`}
            >
              <AnswerBox>
                <p>
                  The amount of salary depends on the workplace, however, the
                  average monthly salary for care workers is 181,220 JPY
                  according to a survey<sup>※</sup>. Before paid, ‘social
                  insurance,’ ‘working taxes’ and ‘residency taxes’ will be
                  deducted. Then you will pay for your meals and bills, for
                  example, rent, electricity, gas, water usage and Wi-Fi, from
                  the rest.
                  <br />
                  The average remittance among interviewees on this website is
                  around 30,000 to 60,000 JPY. Please consider your living
                  expenses and find the best amount for remittance.
                </p>
                <ul className={`notes`}>
                  <li>
                    <i>※</i>
                    <span>
                      <a
                        href={`https://www.mhlw.go.jp/toukei/saikin/hw/kaigo/jyujisya/19/dl/30gaiyou.pdf`}
                        rel={`noopener noreferrer`}
                        target={`_blank`}
                      >
                        ‘Results of “Survey on Working Conditions of Long-term
                        Care Workers”’
                      </a>{" "}
                      (2018) by the Japan Ministry of Health, Labor and Welfare
                    </span>
                  </li>
                </ul>
              </AnswerBox>
            </QuestionAndAnswerToggle>
            <QuestionAndAnswerToggle
              fullWidth={true}
              question={`What should we do when earthquakes, tsunamis or typhoons hit us?`}
            >
              <AnswerBox>
                <p>
                  Japan is ranked as one of the top countries where damage by
                  natural disasters is common. The different types of natural
                  disasters in Japan are earthquakes, tsunamis, typhoons, heavy
                  rains, floods, heavy snow and landslides.
                  <br />
                  The information related to disasters will be informed by the
                  ‘Japan Meteorology Agency,’ so please check on the internet,
                  TV or radio. There are also many applications that provide you
                  with disaster information.
                  <br />
                  Please prepare for disasters and in an emergency, stay calm
                  and act appropriately.
                </p>
                <div class={css.links}>
                  <p>
                    <a
                      href={`https://www.jma.go.jp/jma/kokusai/multi.html`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      ‘Multilingual Information on Disaster Mitigation’
                    </a>{" "}
                    by Japan | The Japan Meteorological Agency
                    <br />
                    <small>
                      （Japanese / English / Chinese / Korean / Pilipino /
                      Vietnamese / Thai / Indonesian / Nepali / Spanish /
                      Portuguese）
                    </small>
                  </p>
                  <p>
                    A disaster information application supervised by Japan,{" "}
                    <a
                      href={`https://www.rcsc.co.jp/safety-tips-en`}
                      rel={`noopener noreferrer`}
                      target={`_blank`}
                    >
                      ‘Safety Tips’
                    </a>{" "}
                    | RC Solution Co.
                    <br />
                    <small>
                      （Japanese / English / Chinese / Korean / Pilipino /
                      Vietnamese / Thai / Indonesian / Nepali / Spanish /
                      Portuguese）
                    </small>
                  </p>
                </div>
              </AnswerBox>
            </QuestionAndAnswerToggle>
          </div>
        </CenterContentLayout>
      </BasicText>
    </LayoutBasic>
  </div>
)
